import React from "react";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Alingua = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      seo={{
        title: "Alingua",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/alingua-og-image.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #003061, #032b54, #02264a, #012242, #011b36 )",
        icons: "#003061",
        navClass: "alingua",
        ogImage: require("../../assets/img/portfolio/alingua_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/alingua/",
      }}
    >
      <PortfolioHeader name="alingua" height="70" />
      <section className="container-fluid alingua-section-1" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Alingua</h1>
              <ul>
                <li>Rebranding</li>
                <li>Strona internetowa</li>
                <li>Stopki e-mailowe</li>
                <li>Bieżące wsparcie i administracja stroną www</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Z biurem tłumaczeń Alingua współpracujemy już od blisko 5 lat. W
                tym czasie w ramach naszej współpracy wypracowaliśmy projekty
                dwóch stron internetowych (poprzedniej oraz obecnej) opartych na
                wielogodzinnych warsztatach UX/UI, a także stworzyliśmy spójną
                identyfikację wizualną firmy do wszelkich międzynarodowych
                działań marketingowych. Firmie Alingua zależało na
                odzwierciedleniu we wszystkich materiałach marketingowych swojej
                filozofii opartej na bardzo wysokiej jakości tłumaczeń,
                ponadprzeciętnym standardzie obsługi klienta i wypracowanym
                przez lata systemie pracy, zgodnym z trzema normami ISO.
                Finalnym celem było nawiązanie szerszej współpracy z globalnymi
                i prestiżowymi markami.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid alingua-section-2">
        <div className="row">
          <div className="col-md-7">
            <div className="color-scheme"></div>
            <img
              src={require("../../assets/img/portfolio/alingua_top_logo.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-md-5">
            <h3>Logo</h3>
            <div className="row">
              <div className="col-sm-6">
                <p>Blue CMYK:</p>
                <h5>
                  <span>100</span>
                  <span>60</span>
                  <span>0</span>
                  <span>55</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>Orange CMYK:</p>
                <h5>
                  <span>0</span>
                  <span>55</span>
                  <span>80</span>
                  <span>5</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>0</span>
                  <span>47</span>
                  <span>97</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>RGB</p>
                <h5>
                  <span>232</span>
                  <span>132</span>
                  <span>57</span>
                </h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>002f61</h5>
              </div>
              <div className="col-sm-6">
                <p>HEX</p>
                <h5>e88439</h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid alingua-section-3">
        <div className="row">
          <div className="col-md-5">
            <div className="inner">
              <h3>Logo</h3>
              <p>
                Wejście na nowe rynki łączyło się z nowymi potrzebami
                marketingowymi firmy i wymagało opracowania zestawu materiałów
                promocyjnych oraz informacyjnych. To z kolei wymagało
                uporządkowania i odświeżenia identyfikacji wizualnej.
                Przeprowadziliśmy rebranding całej marki, zaprojektowaliśmy nowe
                logo i identyfikację wizualną.
              </p>
            </div>
          </div>
          <div className="col-md-7">
            <img
              src={require("../../assets/img/portfolio/alingua_main_1.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid alingua-section-4">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className="scroll_wrapper">
              <div className="img_wrapper">
                <img
                  src={require("../../assets/img/portfolio/alingua_entire_page.jpg")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid alingua-section-5">
        <div className="row">
          <div className="col-lg-5">
            <div className="inner">
              <h3>Strona internetowa</h3>
              <p>
                Nowy serwis internetowy musiał spełniać szereg założeń
                wyznaczonych przez firmę Alingua, a także wypracowanych na
                etapie warsztatów UX/UI tj. rozbudowany formularz zamówień
                (umożliwiający bardzo szybką wycenę), umożliwiać łatwe
                wprowadzanie kolejnych wersji językowych oraz prezentować treści
                w sposób dopasowany do odpowiednich grup docelowych na rynku
                krajowym i zagranicznym.
              </p>
              <br />
              <a
                href="https://alingua.pl/"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className="btn_custom"
              >
                Zobacz realizację
              </a>
            </div>
          </div>
          <div className="col-lg-7 text-right">
            <img
              src={require("../../assets/img/portfolio/alingua_main_2.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/portfolio/alingua_main_3.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/portfolio/alingua_main_4.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/portfolio/alingua_main_5.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/portfolio/alingua_main_6.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="col-lg-6">
            <img
              src={require("../../assets/img/portfolio/alingua_main_7.png")}
              className="img-fluid"
              alt=""
            />
          </div>
        </div>
      </section>

      <section className="container-fluid alingua-section-6">
        <div className="row">
          <div className="col-md-6">
            <img
              src={require("../../assets/img/portfolio/alingua_main_8.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="offset-md-1 col-md-5">
            <div className="inner">
              <h3>Stopki e-mail</h3>
              <p>
                Zaprojektowaliśmy również stopki e-mail tak, aby codzienna
                korespondencja była spójna z identyfikacją wizualną całej marki
                i przedstawiała potencjalnym kontrahentom, że firma Alingua
                zawsze dba nawet o najmniejszy szczegół.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="container-fluid alingua-section-7">
        <div className="inner">
          <h3>Bieżące wsparcie i administracja serwisem www</h3>
          <p>
            Obecnie zajmujemy się zarządzaniem stroną firmy w ramach naszej
            usługi ForSecure (www.forsecure.pl), co wiąże się z jej regularnymi
            aktualizacjami, zabezpieczeniem, a także bieżącym serwisowaniem oraz
            dalszym rozwojem strony o nowe funkcjonalności na życzenie naszego
            partnera.
          </p>
          <img
            src={require("../../assets/img/forsecure.png")}
            className="img-fluid"
            alt=""
          />
        </div>
      </section>

      <section className="container-fluid alingua-section-8">
        <div className="row">
          <div className="offset-lg-2 col-lg-2 offset-md-1 col-md-3 photo-wrapper">
            <img
              src={require("../../assets/img/portfolio/alingua_reference_photo.png")}
              className="img-fluid"
              alt=""
            />
          </div>
          <div className="offset-md-1 col-md-6">
            <div className="inner">
              <h3>Referencje</h3>

              <p>
                Podjęte przez firmę projekty są realizowane w niezmiennym
                zaangażowaniem. Ważnym dla nas atutem firmy jest pełna
                dyspozycyjność pracowników udzielających wsparcia w każdej
                wymagającej tego sytuacji. Życzliwe i otwarte nastawienie do
                wymagań klienta zachęca do kontynuowania współpracy.
              </p>

              <p>
                <strong>Piotr Gabor</strong>
                <br />
                Co-Founder & Member of the Board
                <br />
                Alinqua
              </p>
            </div>
          </div>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Alingua;
